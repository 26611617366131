import React from 'react';
import PropTypes from 'prop-types';

const FieldNote = ({ note }) =>
  note && (
    <span data-testid="fieldNote" className="field__note">
      {note}
    </span>
  );

FieldNote.propTypes = {
  note: PropTypes.string.isRequired,
};

export default FieldNote;
