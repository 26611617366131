import React from 'react';
import PropTypes from 'prop-types';

const InputError = ({ errorMsg }) =>
  errorMsg && (
    <span data-testid="inputError" className="field__error">
      {errorMsg}
    </span>
  );

InputError.propTypes = {
  errorMsg: PropTypes.string.isRequired,
};

export default InputError;
