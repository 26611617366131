import { FC, Ref, forwardRef } from 'react';
import classNames from 'classnames';

import Tooltip from 'react-tooltip-lite';
import { ReactSVG } from 'react-svg';
import FieldNote from '../fieldNote';
import InputError from '../inputError';

import useIsMobile from '../../../hooks/useIsMobile';

import {
  MOBILE_TOOLTIP_DISTANCE,
  SHARED_TOOLTIP_DISTANCE,
} from '../../../constants/breakpoints';

interface Props {
  type?: 'text' | 'email' | 'search' | 'password' | 'number' | 'textarea';
  label: string;
  name: string;
  value: string;
  maxLength?: number;
  onChange: (any) => any;
  readonly?: boolean;
  hideLabel?: boolean;
  showDropArrow?: boolean;
  error?: boolean;
  errorMsg?: string;
  note?: string;
  className?: string;
  dataCy?: string;
  tooltip: string;
}

const Textbox: FC<Props> = (
  {
    type = 'text',
    label,
    name,
    tooltip,
    value = '',
    onChange = () => {},
    readonly = false,
    hideLabel = true,
    showDropdownArrow = false,
    error = false,
    errorMsg = '',
    className: classNameProp,
    dataCy = 'textbox',
    maxLength = null,
    note = null,
    ...props
  },
  forwardedRef: Ref
) => {
  const className = classNames(
    'field',
    'field--text',
    {
      'field--hidden-label': hideLabel,
      'field--dropdown-arrow': showDropdownArrow,
      'field--has-error': !!error,
    },
    classNameProp
  );

  const isMobileView = useIsMobile();

  const truncateToMaxLength = text =>
    maxLength && text ? String(text).slice(0, maxLength) : text;

  const handleOnChange = event => {
    const newEvent = {
      ...event,
      target: {
        ...event.target,
        name: event.target.name,
        value: truncateToMaxLength(event.target.value),
      },
    };
    onChange(newEvent);
  };

  return (
    <div className={className} data-testid="textbox">
      <label className="field__label" htmlFor={name}>
        {label}
      </label>
      <div className="field__input">
        <input
          ref={forwardedRef}
          data-testid={`${name}Input`}
          autoComplete="off"
          id={name}
          data-cy={`${dataCy}Input`}
          {...props}
          type={type}
          name={name}
          value={truncateToMaxLength(value)}
          maxLength={maxLength}
          onChange={handleOnChange}
          readOnly={readonly}
        />
        {tooltip && (
          <div data-testid="tooltip">
            <Tooltip
              content={tooltip}
              className="tooltip"
              distance={
                isMobileView ? MOBILE_TOOLTIP_DISTANCE : SHARED_TOOLTIP_DISTANCE
              }
            >
              <ReactSVG
                src="/images/icons/blue-question-mark.svg"
                width={17}
                height={17}
              />
            </Tooltip>
          </div>
        )}
      </div>
      {error && <InputError errorMsg={errorMsg} />}
      {note && <FieldNote note={note} />}
    </div>
  );
};

export default forwardRef(Textbox);
